<template>
  <div>
    <div class='container' v-if='logout'>
      <div class="row">
        <div class="text-link-darkgray">
          Session vorbei. Bitte loggen Sie sich neu ein.
        </div>
      </div>
    </div>
    <div class='container' v-if='dataReady'>
      <div class='row'>
        <router-link to='/osa-home'>
          <div class='text-link-darkgray'>
            Zurück zur Übersicht
          </div>
        </router-link>
      </div>
      <div class='row'>
        <h1>Das Online Self Assessment (OSA) Informatik der TU Wien</h1>
      </div>
      <div class='row'>
        <img :src='headerImg' alt='Informatiker:innen bei der Arbeit' style='width: 100%; margin-bottom: 20px' />
      </div>
      <a class="credit" target="_blank">© TU Wien Space Team</a>
      <div class='row'>
        <p>
          Ziehen Sie die Aufnahme eines Informatikstudiums und den Beruf
          Informatiker:in für sich in Betracht? Toll! Da sind Sie richtig bei
          uns.
        </p>

        <p>
          Wir wollen Ihnen mit dem Online Self Assessment (OSA) Informatik die
          Möglichkeit geben, mehr über das Studium an der TU Wien und die
          Tätigkeit im Informatikberuf zu erfahren. Darüber hinaus setzen Sie sich
          mit Ihren persönlichen Voraussetzungen für Studium und Beruf
          auseinander. Die dabei gewonnenen Erkenntnisse sollen Sie anregen, Ihre
          persönliche Eignung für das Informatikstudium nicht nur zu überprüfen,
          sondern sie gezielt weiterzuentwickeln. Ein solcher Schritt vor Aufnahme
          eines Studiums kann Ihnen helfen, um Klarheit zu gewinnen und eine gut
          begründete Entscheidung über die Studienwahl zu treffen.
        </p>

        <p>
          Für das OSA Informatik haben wir für Sie verschiedene Informationen
          zusammengetragen sowie Aufgaben und Fragebögen entwickelt. Für deren
          Bearbeitung sollten Sie ca. 90 - 120 Minuten vorsehen. Sie können zwischen den einzelnen Teilen unterbrechen
          und später fortsetzen.
          Die Informationsteile und Ihre Ergebnisse können Sie immer wieder ansehen, die Aufgaben und Fragebögen
          allerdings nicht wiederholt bearbeiten.
        </p>
      </div>

      <div class='row'>
        <h2>Inhalte des OSA Informatik</h2>
      </div>

      <div class='row'>
        <p>
          <strong>Was konkret bieten wir Ihnen an?</strong>
        </p>
        <p>
          Das OSA Informatik setzt sich aus verschiedenen Teilen zusammen:
        </p>

        <ul style='padding-left: 50px'>
          <li>
            <p>
              Im <em>ersten Teil</em> möchten wir Sie mit ausgewählten Themen und Inhalten aus der Informatik vertraut
              machen.
              Hier finden Sie auch wichtige Informationen zum Studium an der TU Wien.
            </p>
          </li>

          <li>
            <p>
              Der <em>zweite Teil</em> beinhaltet die Lösung von alltäglichen Problemen mit den Mitteln der Informatik.
              Bei der Bearbeitung dieser Aufgaben erfahren Sie, wie in der Informatik gedacht und vorgegangen wird.
              Sie können für sich prüfen, inwieweit Ihnen derartige Anforderungen liegen.
            </p>
            <p><strong><em>Hinweis:</em></strong> Es ist erforderlich, mindestens vier der fünf Module aus dem zweiten
              Teil zu absolvieren. Nur dann erhalten Sie Zugang zu allen relevanten Informationen im vierten Teil des
              OSA Informatik, die notwendig sind, um Ihre Voranmeldung für die Bachelorstudiengänge Informatik und
              Wirtschaftsinformatik zu aktivieren.</p>
          </li>

          <li>
            <p>
              Im <em>dritten Teil</em> nehmen Sie Selbsteinschätzungen zu Ihren persönlichen Eignungsvoraussetzungen für
              das Informatikstudium und den Informatikberuf vor.
            </p>
          </li>

          <li>
            <p>
              Im <em>vierten und letzten Teil</em> finden Sie Ihre Ergebnisse. Dafür müssen Sie alle anderen Teile
              absolviert haben.
            </p>
          </li>
        </ul>
      </div>

      <div class='user-study-comment'>
        <p>
          Sie haben alle vier Module fertig bearbeitet und die Fragebögen ausgefüllt?<br />
        </p>
        <p>
          Sie können die User Study abschließen indem Sie folgenden finalen Fragebogen öffnen und beantworten.
        </p>

        <div class style='display: flex; justify-content: center'>
          <a href='https://survey.media.tuwien.ac.at/index.php/884988?lang=de' target='_blank'>
            <div class='custom-btn' @click='questionnaireOpened = true'>
              Zum Fragebogen
            </div>
          </a>
        </div>
      </div>

      <!-- MODDULES -->
      <div class='modules'>
        <div class='row module'>
          <div class='module-header'>
            <h3>
              Teil 1: Über Informatik und Informatikstudium an der TU Wien
            </h3>
            <div class='check-container'>
              <div class='module-finished-check' v-if='this.user'>
                <i class='bi bi-check' :class='{ green: this.myInformatikOSA.introFinished }'>
                </i>
                <div class="check-text" v-if="this.myInformatikOSA.introFinished">abgeschlossen</div>
                <div class="check-text" v-else>in Bearbeitung</div>
              </div>
            </div>
          </div>

          <div class="module-text line-height-adjusted">
            In diesem Einstiegsmodul erhalten Sie einen Überblick über den Fach- und Anwendungsbereich Informatik.
            Informatik ist eine junge aber mittlerweile sehr breite Wissenschaft. Alle ihre Gebiete können sicherlich
            nicht in Kürze dargestellt werden.
            Hier erfahren Sie neben der Vielschichtigkeit und dem Umfang der Informatik als Fachgebiet, was Sie an der
            Fakultät für Informatik der TU Wien studieren können.
            <!--<div class = 'user-study-comment'>
              <p>Nicht finalisierte Stylesheets.</p>
              </div>-->
          </div>
          <div>
            <div class="custom-btn float-end" @click="startInformatikIntro">
              Start
            </div>
          </div>
        </div>

        
        <div class="row module">
          <div class="module-header">
            <div class="module-title">
              <h3>Teil 2: Variablen </h3>
            </div>
            <div class="check-container">
              <div class="module-finished-check" v-if="this.user">
                <i class="bi bi-check" :class="{ green: this.myInformatikOSA.variablesFinished }"></i>
                
                <div class="check-text" v-if="this.myInformatikOSA.variablesFinished">abgeschlossen</div>
                <div class="check-text" v-else>in Bearbeitung</div>
              </div>
            </div>
          </div>
          <div class="module-text line-height-adjusted">
            <p>In der Informatik spielen Variablen eine fundamentale Rolle als Grundbausteine der Programmierung und
              Datenverarbeitung.
              Variablen dienen als Speicherorte im Arbeitsspeicher eines Computers, in denen Werte abgelegt und während
              der Laufzeit eines Programms abgerufen oder verändert werden können.
              Sie können sich diese vorstellen wie Etiketten oder Namen, die Datenwerten zugeordnet werden, um deren
              Handhabung und Verarbeitung zu vereinfachen.</p>

            <p>In diesem Modul erfahren Sie, was Variablen sind und wie sie aufgebaut
              werden, wo sie gebraucht werden, wie sie in einem Programm sinnvoll
              verwendet werden können, wie die Werte zwischen den Variablen getauscht
              werden können. Dafür haben wir für Sie auch interaktive Beispiele vorbereitet.
              Viel Spaß damit!</p>

            <div class='user-study-comment'>
              <p>Modul läuft im Testmodus und ist nicht mit dem Backend verbunden.</p>
              <p>Autoscrolling nicht implementiert.</p>
            </div>
          </div>
          <router-link class="module-error" to="/" v-if="!this.user">
            Zur Bearbeitung dieses Moduls müssen Sie eingeloggt sein.
          </router-link>
          <div>
            <div class="custom-btn float-end" :class="user ? '' : 'custom-btn-disabled'" @click="startVariabeln">
              Start
            </div>
          </div>
        </div>

        <div class="row module">
          <div class="module-header">
            <h3>
              Teil 2: Mit Sicherheit
            </h3>
            <div class="check-container">
              <div class="module-finished-check" v-if="this.user">
                <i class="bi bi-check" :class="{ green: this.myInformatikOSA.securityFinished }"></i>
                
                <div class="check-text" v-if="this.myInformatikOSA.securityFinished">abgeschlossen</div>
                <div class="check-text" v-else>in Bearbeitung</div>
              </div>
            </div>
          </div>
          <div class="module-text line-height-adjusted">
            <p>
              Die meisten Menschen haben schon mal von Hackerangriffen gehört.
              Sie wissen, dass bei sensiblen Daten im Internet Vorsicht geboten ist.
              Um solche Daten zu schützen, werden unterschiedliche Methoden der Verschlüsselung verwendet.
              Aber wie funktioniert Verschlüsselung im Internet eigentlich?
            </p>
            <p>
              In diesem Modul erfahren Sie, welche Verschlüsselungsmethoden es gibt und wie diese in der Informatik
              angewendet werden.
              Interaktiv probieren Sie selber aus, wie entschlüsselt wird. Sehr nützlich.
            </p>
            <div class='user-study-comment'>
              <p>Nicht finalisierte Stylesheets.</p>
            </div>
          </div>
          <router-link class="module-error" to="/" v-if="!this.user">
            Zur Bearbeitung dieses Moduls müssen Sie eingeloggt sein.
          </router-link>
          <div>
            <div class="custom-btn float-end" :class="user ? '' : 'custom-btn-disabled'" @click="startSicherheit">
              Start
            </div>
          </div>
        </div>


       
       
        <div class="row module">
          <div class="module-header">
            <div class="module-title">
              <h3>Teil 2: Datenbanken </h3>
            </div>
            <div class="check-container">
              <div class="module-finished-check" v-if="this.user">
                <i class="bi bi-check" :class="{ green: this.myInformatikOSA.databaseFinished }"></i>
                <div class="check-text" v-if="this.myInformatikOSA.databaseFinished">abgeschlossen</div>
                <div class="check-text" v-else>in Bearbeitung</div>
              </div>
            </div>
          </div>
          <div class="module-text line-height-adjusted">
            <p>Datenbanken ermöglichen die Erfassung, Speicherung und Bereitstellung verwandter, strukturierten Daten
              mit wohldefinierten Datenformaten. Sie
              unterstützen die effiziente Abfrage, Veränderung und Analyse dieser Daten, um Informationsbedürfnisse in
              verschiedenen Anwendungsbereichen zu
              erfüllen. Durch den Einsatz von Datenbankmanagementsystemen (DBMS) wird die Integrität, Sicherheit und
              Zugänglichkeit der Daten gewährleistet.</p>

            <p>In diesem Modul ergründen Sie die Grundlagen von Datenbanken, einschließlich des Aufbaus von SQL
              (Structured Query Language), Methoden
              zum Hinzufügen und Abfragen von Daten, sowie wesentlicher DBMS-Anweisungen und Funktionen, wobei
              besonderes Augenmerk auf die Syntax
              gelegt wird. Entdecken Sie diese durch praktische Anwendung selbst!</p>

            <div class='user-study-comment'>
              <p>Modul in Entwicklung.</p>
            </div>
          </div>
          <router-link class="module-error" to="/" v-if="!this.user">
            Zur Bearbeitung dieses Moduls müssen Sie eingeloggt sein.
          </router-link>
          <div>
            <div class="custom-btn float-end" :class="user ? '' : 'custom-btn-disabled'" @click="startDatenbanken">
              Start
            </div>
          </div>
        </div>

        <div class="row module">
          <div class="module-header">
            <div class="module-title">
              <h3>Teil 2: Graphen </h3>
            </div>
            <div class="check-container">
              <div class="module-finished-check" v-if="this.user">
                <i class="bi bi-check" :class="{ green: this.myInformatikOSA.graphFinished }"></i>
                <div class="check-text" v-if="this.myInformatikOSA.graphFinished">abgeschlossen</div>
                <div class="check-text" v-else>in Bearbeitung</div>
              </div>
            </div>
          </div>
          <div class="module-text line-height-adjusted">
            <p>Komplexe Datenstrukturen mit unzähligen Verbindungen zwischen einzelnen Datenpunkten können schnell
              unübersichtlich werden. Durch Graphen können wir diese Daten strukturiert und verständlich darstellen.
              Daher sind sie ein nahezu allgegenwärtiges Themengebiet in der Informatik und erlauben es
              Daten in einer visuellen Form darzustellen.
            </p>
            <p>In diesem Modul erfahren Sie wie Graphen in der Informatik verwendet werden und welche unterschiedlichen
              Arten von Graphen es gibt. Sie werden die Grundlagen kennen lernen, die in der Informatik angewendet
              werden um Graphen zu erstellen und zu analysieren. Tauchen sie selbst in die Welt der Graphen ein und versuchen
              Sie Daten auf eine neue Art und Weise darzustellen!
            </p>
            <div class='user-study-comment'>
              <p>Modul in Entwicklung.</p>
            </div>
          </div>
          <router-link class="module-error" to="/" v-if="!this.user">
            Zur Bearbeitung dieses Moduls müssen Sie eingeloggt sein.
          </router-link>
          <div>
            <div class="custom-btn float-end" :class="user ? '' : 'custom-btn-disabled'" @click="startGraph">
              Start
            </div>
          </div>
        </div>

        <div class="row module">
          <div class="module-header">
            <h3>Teil 2: Sortieren mit System</h3>
            <div class="check-container">
              <div class="module-finished-check" v-if="this.user">
                <i class="bi bi-check" :class="{ green: this.myInformatikOSA.sortingFinished }"></i>
                <div class="check-text" v-if="this.myInformatikOSA.sortingFinished">abgeschlossen</div>
                <div class="check-text" v-else>in Bearbeitung</div>
              </div>
            </div>
          </div>
          <div class="module-text">
            <p>
              Menschen sind zumeist in der Lage, wenige Elemente intuitiv nach einem Merkmal zu sortieren.
              Sie stoßen aber bei größeren Mengen schnell an ihre Grenzen. Für einen Computer hingegen macht es kaum
              einen Unterschied.
              Aber wie sortieren Computerprogramme eigentlich?
            </p>
            <p>
              In diesem Modul erfahren Sie, wie Sie einem Computer das Sortieren beibringen können. Dazu werden Sie
              einen sogenannten Sortieralgorithmus erstellen. Es ist interaktiv und macht Spaß.
            </p>
          </div>
          <router-link class="module-error" to="/" v-if="!this.user">
            Zur Bearbeitung dieses Moduls müssen Sie eingeloggt sein.
          </router-link>
          <div>
            <div class="custom-btn float-end" :class="user ? '' : 'custom-btn-disabled'" @click="startSortierung">
              Start
            </div>
          </div>
        </div>


        <div class="row module">
          <div class="module-header">
            <h3>Teil 3: Fragebogen: Fit für das Informatikstudium</h3>
            <div class="check-container">
              <div class="module-finished-check" v-if="this.user">
                <i class="bi bi-check" :class="{ green: this.myInformatikOSA.fitIFinished }"></i>
                <div class="check-text" v-if="this.myInformatikOSA.fitIFinished">abgeschlossen</div>
                <div class="check-text" v-else>in Bearbeitung</div>
              </div>
            </div>
          </div>
          <div class="module-text line-height-adjusted">
            Durch den Fragebogen „Fit für das Informatikstudium“ haben Sie die Möglichkeit, Ihre Erwartungen und Ihre
            Motivation an einem Informatikstudium und dem Berufszweig Informatik zu erfahren.
            Durch gut gewählte Fragen finden Sie raus, was Ihnen liegt und was Sie eher uninteressant finden. Es lohnt
            sich, mal zu sehen, was Sie eigentlich zum Informatikstudium bzw. einen Informatikberuf meinen. Das erfahren
            Sie hier.
            <!--<div class = 'user-study-comment'>
            <p>Nicht finalisierte Stylesheets.</p>
          </div>-->
          </div>
          <router-link class="module-error" to="/" v-if="!this.user">
            Zur Bearbeitung dieses Moduls müssen Sie eingeloggt sein.
          </router-link>
          <div>
            <div class="custom-btn float-end" :class="user ? '' : 'custom-btn-disabled'" @click="startFitI">
              Start
            </div>
          </div>
        </div>
        <div class="row module">
          <div class="module-header">
            <h3>Teil 4: Ergebnisse</h3>
          </div>
          <div class="module-text line-height-adjusted">
            Nachdem Sie alle Module des OSA Informatik absolviert haben, finden Sie hier eine detailliertere Darstellung
            Ihrer Ergebnisse.
            Dazu müssen aber <strong>alle</strong> der obigen Module vorher abgeschlossen werden.
            Es kann überraschend oder bestätigend sein, wenn Sie sehen, was Sie alles im OSA Informatik gemacht haben.
            Sind Sie neugierig über Ihre Ergebnisse? Dann schauen Sie da rein.

            <!--<div class = 'user-study-comment'>
            <p>Modul läuft im Testmodus und es müssen nicht alle anderen Module vorher absolviert werden.</p>
          </div>-->
          </div>
          <router-link class="module-error" to="/" v-if="!this.user">
            Zur Bearbeitung dieses Moduls müssen Sie eingeloggt sein.
          </router-link>
          <div>
            <div class="custom-btn float-end" :class="(user || !(this.modulesFinished())) ? '' : 'custom-btn-disabled'"
              @click="startResults">
              Meine Ergebnisse ansehen
            </div>
          </div>
        </div>
      </div>
      <a>Design- und Implementierungsteam dieser Version {{ versionNumber }} ({{ versionDate }}): Philipp Klein, Stefan
        Forster, Ola Kukaj, Eduardo Alirio Arreaza, Hilda Tellioglu</a>
    </div>
  </div>
</template>

<script>
import * as scrollHelper from "@/helpers/scroll-helper.js";
import * as myOSAhandler from "@/scripts/inf_my-osa-api-handler.js";

export default {
  name: "InformatikHome",
  components: {},
  props: {},
  emits: [],
  data() {
    return {
      logout: false,
      dataReady: false,
      user: {},
      showSortierungTransition: false,
      headerImg: require("@/assets/inf/informatik_title_image.jpg"),
      myInformatikOSA: {},
      versionNumber: process.env.VUE_APP_VERSION_NUMBER,
      versionDate: process.env.VUE_APP_VERSION_DATE
    };
  },
  async mounted() {
    scrollHelper.scrollToTop();
    this.$store.dispatch("setHeaderPageTitle", "Online Self Assessment Informatik");
    try {
      this.user = JSON.parse(JSON.stringify(this.$store.getters.getUser));
      this.myInformatikOSA = await myOSAhandler.getMyInformatikOSA(this.user);
      this.dataReady = true;
    }
    catch (error) {
      this.logout = true;
    }
  },
  methods: {
    async modulesFinished() {
      const myOSA = await myOSAhandler.getMyInformatikOSA(
        this.$store.getters.getUser.username
      );
      this.myOSA = myOSA;

      //let introFinished = myOsa.introFinished;
      //let sortingFinished = myOSA.sortingFinished;
      //let securityFinished = myOsa.securityFinished;
      //let fitIFinished = myOsa.fitiIFinished;

      return true;
    },
    startInformatikIntro() {
      this.$router.push("/informatik/introduction");
      if (this.user && Object.entries(this.myInformatikOSA).length === 0) {
        myOSAhandler.createMyInformatikOSA(this.user);
      }
    },
    startSortierung() {
      if (this.user) {
        this.$router.push("/informatik/sorting");
        if (Object.entries(this.myInformatikOSA).length === 0) {
          myOSAhandler.createMyInformatikOSA(this.user);
        }
      }
    },
    startSicherheit() {
      if (this.user) {
        this.$router.push("/informatik/security");
        if (Object.entries(this.myInformatikOSA).length === 0) {
          myOSAhandler.createMyInformatikOSA(this.user);
        }
      }
    },
    startVariabeln() {
      if (this.user) {
        this.$router.push("/informatik/variables");
        if (Object.entries(this.myInformatikOSA).length === 0) {
          myOSAhandler.createMyInformatikOSA(this.user);
        }
      }
    },
    startDatenbanken() {
      if (this.user) {
        this.$router.push("/informatik/database");
        if (Object.entries(this.myInformatikOSA).length === 0) {
          myOSAhandler.createMyInformatikOSA(this.user);
        }
      }
    },
    startGraph() {
      if (this.user) {
        this.$router.push("/informatik/graphs");
        if (Object.entries(this.myInformatikOSA).length === 0) {
          myOSAhandler.createMyInformatikOSA(this.user);
        }
      }
    },
    startFitI() {
      if (this.user) {
        this.$router.push("/informatik/fiti");
        if (Object.entries(this.myInformatikOSA).length === 0) {
          myOSAhandler.createMyInformatikOSA(this.user);
        }
      }
      //setTimeout(function(){ajaxLogin(document.getElementById('logininp').value,document.getElementById('code').value);},10);
      //https://coping-tests.eu/login.php
    },
    startResults() {
      if (this.user && this.modulesFinished()) {
        this.$router.push("/informatik/results");
      }
    },
  },
};
</script>

<style scoped>
a {
  font-size: 10px;
  /* or even smaller if you want */
  color: #555;
  /* Optional: change the color if you like */
  text-align: center;
  /* Optional: centers the link */
  display: block;
  /* Optional: places the link on its own line */
  margin-top: 5px;
  /* Optional: adds space above the link */
}

.module {
  border: 1px solid black;
  border-radius: 10px;
  padding: 10px 10px 5px 10px;
  margin: 30px;
  column-span: none;


}

.module-header {
  display: flex;
  flex-direction: row;
  align-items: center;

}

.check-container {
  margin-left: 20px;
}

.check-text{
  font-size: 0.9rem;
  color: rgb(114, 114, 114);
}

.module-text {
  line-height: 1.2;
}

a {
  text-decoration: none;
}

.module-error {
  margin-top: 20px;
  color: red;
  text-decoration: underline;
}

.module-error:hover {
  color: darkred;
}

.module-finished-check {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 3em;
  color: #bababa;
}

.green {
  color: green;
}

p {
  line-height: 1.2;
}
</style>
